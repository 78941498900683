td {
  min-width: 120px;
}

.tool-carousel {
  height: 66px;
}

.flatpickr-input {
  width: 100%;
}

.splide__arrow svg {
  fill: #17a2b8;
}

.new .splide__arrow svg {
  fill: #28a745;
}

.modal-title {
  font-size: 1.5rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
}

.close {
  opacity: 1;
}
