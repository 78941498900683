.click {
  &-input {
    width: 100%;
  }

  &-text {
    display: inline-block;
    min-height: 1.5rem;
    min-width: 100%;
    word-break: break-all;
  }
}
